var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    height: _vm.statusBarHeights + 'px',
    width: '100vw',
    background: '#fff',
    position: 'fixed',
    top: '0px',
  })},[_c('header',{style:({ 'margin-top': _vm.statusBarHeight + 'px' })},[_c('div',{staticClass:"nav-btn-group"},[_c('router-link',{attrs:{"to":{
          name: 'index',
          query: {
            merchantCode: this.$route.query.merchantCode,
            merchantAccessToken: this.$route.query.merchantAccessToken,
            uniappLogin: this.$route.query.uniappLogin,
            uniappLoginYy: this.$route.query.uniappLoginYy },
        },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
        var isExactActive = ref.isExactActive;
return [(!isExactActive && _vm.$route.path != '/')?_c('button',{staticClass:"index-button",on:{"click":navigate}},[_vm._v(" 主頁 ")]):_vm._e()]}}])}),_c('router-link',{attrs:{"to":{ name: 'caseRecords' ,query:{
            merchantCode: this.$route.query.merchantCode,
            merchantAccessToken: this.$route.query.merchantAccessToken,
            uniappLogin: this.$route.query.uniappLogin,
            uniappLoginYy: this.$route.query.uniappLoginYy
        }},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
        var isExactActive = ref.isExactActive;
return [(!isExactActive&&_vm.$route.path!='/case-records')?_c('button',{staticClass:"case-records-button",on:{"click":navigate}},[_vm._v(" 問診記錄 ")]):_vm._e()]}}])}),_c('router-link',{attrs:{"to":{ name: 'profileIndex' ,query:{
            merchantCode: this.$route.query.merchantCode,
            merchantAccessToken: this.$route.query.merchantAccessToken,
            uniappLogin: this.$route.query.uniappLogin,
            uniappLoginYy: this.$route.query.uniappLoginYy
        }},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
        var isExactActive = ref.isExactActive;
return [(!isExactActive)?_c('button',{staticClass:"profile-button",on:{"click":navigate}},[_vm._v(" 檔案 ")]):_vm._e()]}}])})],1),_c('common-questions')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }