<template>
  <div
    :style="{
      height: statusBarHeights + 'px',
      width: '100vw',
      background: '#fff',
      position: 'fixed',
      top: '0px',
    }"
  >
    <header :style="{ 'margin-top': statusBarHeight + 'px' }">
      <div class="nav-btn-group">
        <router-link
          :to="{
            name: 'index',
            query: {
              merchantCode: this.$route.query.merchantCode,
              merchantAccessToken: this.$route.query.merchantAccessToken,
              uniappLogin: this.$route.query.uniappLogin,
              uniappLoginYy: this.$route.query.uniappLoginYy },
          }"
          v-slot="{ navigate, isExactActive }"
          custom
        >
          <button
            @click="navigate"
            class="index-button"
            v-if="!isExactActive && $route.path != '/'"
          >
            主頁
          </button>
        </router-link>
        <router-link
          :to="{ name: 'caseRecords' ,query:{
              merchantCode: this.$route.query.merchantCode,
              merchantAccessToken: this.$route.query.merchantAccessToken,
              uniappLogin: this.$route.query.uniappLogin,
              uniappLoginYy: this.$route.query.uniappLoginYy
          }}"
          v-slot="{ navigate, isExactActive }"
          custom
        >
          <button
            @click="navigate"
            v-if="!isExactActive&&$route.path!='/case-records'"
            class="case-records-button"
          >
            問診記錄
          </button>
        </router-link>
        <router-link
          :to="{ name: 'profileIndex' ,query:{
              merchantCode: this.$route.query.merchantCode,
              merchantAccessToken: this.$route.query.merchantAccessToken,
              uniappLogin: this.$route.query.uniappLogin,
              uniappLoginYy: this.$route.query.uniappLoginYy
          }}"
          v-slot="{ navigate, isExactActive }"
          custom
        >
          <button
            @click="navigate"
            v-if="!isExactActive"
            class="profile-button"
          >
            檔案
          </button>
        </router-link>
      </div>
      <common-questions></common-questions>
    </header>
  </div>
</template>

<script>
import commonQuestions from "./commonQuestions.vue";
export default {
  components: { commonQuestions },
  data: function () {
    return {
      statusBarHeight: 0,
      statusBarHeights: 45,
    };
  },
  watch: {},
  computed: {},
  created() {
    // ios头部内容适配问题
    if (this.$store.state.statusBarHeight > 0) {
      this.statusBarHeight = parseInt(this.$store.state.statusBarHeight);
      this.statusBarHeights =
        this.statusBarHeights + parseInt(this.$store.state.statusBarHeight);
    }
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}

header {
  width: 100vw;
  background-color: white;
  padding: 10px 16px;
  height: 45px;
  @include flexr-between-center;
  .nav-btn-group {
    width: 50%;
    @include flexr-start-center;
  }
}

@mixin navigate-button($color) {
  height: 25px;
  padding: 0 8px;
  line-height: 25px;
  font-size: 12px;
  background-color: transparent;
  border: 1px solid $color;
  border-radius: 15px;
  color: $color;
  margin-right: 8px;
}

.index-button {
  @include navigate-button($green-300);
}

.case-records-button {
  @include navigate-button($yellow-100);
}

.profile-button {
  @include navigate-button($blue-200);
}

button:last-of-type {
  margin-right: 0;
}
</style>